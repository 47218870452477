<template>
  <div>
    <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            slim
            :rules="{ required: true, double: true, min_value: 1 }"
            name="id"
          >
            <v-text-field
              v-model.number="id"
              dense
              outlined
              :error-messages="errors"
              :label="
                challengeType === 'complexch'
                  ? 'ID Агрегатного испытания'
                  : 'ID испытания'
              "
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col md="3" sm="3" xs="12">
          <validation-provider
            v-slot="{ errors }"
            slim
            :rules="{ required: true, double: true, min_value: 0 }"
            name="score"
          >
            <v-text-field
              v-model.number="score"
              dense
              outlined
              :error-messages="errors"
              :label="challengeType === 'complexch' ? 'Коэффициент' : 'Баллы'"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <div v-if="error" class="error--text my-2">{{ error }}</div>

      <v-btn color="primary" :loading="pending" type="submit">Добавить</v-btn>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "ChallengeSettingForm",
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    challengeType: {
      type: String,
      required: true,
      validate(value) {
        return ["cahllenge", "complexch"].includes(value);
      },
    },
  },
  data() {
    return {
      id: undefined,
      score: undefined,
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.$emit("submit", {
        id: this.id,
        score: this.score,
      });

      // reset form
      this.id = undefined;
      this.score = undefined;
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
