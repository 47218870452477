<template>
  <div>
    <v-sheet class="pa-4">
      <h4 class="mb-4">Добавить настройку</h4>
      <challenge-form
        :pending="createPending"
        :error="createError"
        challenge-type="challenge"
        @submit="addSetting"
      />
    </v-sheet>
    <v-divider class="my-4"></v-divider>
    <div style="min-height: 6px">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="pending"
      ></v-progress-linear>
    </div>
    <div v-if="!!list.length">
      <v-simple-table class="mb-3">
        <template #default>
          <thead>
            <tr>
              <th class="text-left">ID Испытания</th>
              <th class="text-left" width="120px">Балл</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list" :key="item.id">
              <td>{{ item.challenge_id }}</td>
              <td>{{ item.score }}</td>
              <td class="text-right">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'error' : 'grey lighten-1'"
                    title="Удалить настройку"
                    icon
                    @click.stop="removeSetting(item.id)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-hover>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <app-pager
        :value="page"
        :total="total"
        :limit="limit"
        @input="getList"
      ></app-pager>
    </div>

    <div v-else-if="!pending">Список настроек пуст</div>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import ChallengeForm from "./challenge/ChallengeForm.vue";

export default {
  name: "ChallengeSettings",
  components: {
    ChallengeForm,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      page: 1,
      limit: 3,
      total: 0,
      list: [],
      error: "",
      createError: "",
      createPending: false,
    };
  },

  created() {
    this.getList(1);
  },

  methods: {
    async getList(page = 1) {
      const { limit } = this;
      if (this.pending) return;
      this.pending = true;
      const offset = Math.ceil(page * limit - limit);
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/tracks/${this.track.id}/settings/challenge`,
          params: {
            limit,
            offset,
          },
        });
        this.list = data.results;
        this.page = page;
        if ("count" in data) {
          this.total = data.count;
        }
      } catch (error) {
        console.log("error", error);
        this.error = error.message || "Возникла непредвиденная ошибка";
      } finally {
        this.pending = false;
      }
    },
    async removeSetting(id) {
      this.list = this.list.filter((n) => n.id !== id);
      await apiClient({
        method: "DELETE",
        url: `/tracks/${this.track.id}/settings/challenge/${id}`,
      });

      if (this.list.length > 0) {
        this.getList(Math.max(this.page));
      } else {
        this.getList(Math.max(this.page - 1, 1));
      }
      this.$toast(`Настройка для испытания ${id} удалена.`, {
        type: "success",
      });
    },
    async addSetting(setting) {
      if (this.createPending) return;
      this.createError = "";
      this.createPending = true;
      try {
        await apiClient({
          method: "POST",
          url: `/tracks/${this.track.id}/settings/challenge`,
          data: {
            challenge_id: setting.id,
            score: setting.score,
          },
        });
        this.getList(1);
      } catch (error) {
        this.createError = error.message;
      }
      this.createPending = false;
    },
  },
};
</script>

<style></style>
